<div class="dialog-container">
  <mat-grid-list cols="3" rowHeight="1:.25">
    <mat-grid-tile colspan="3" rowspan="1">
      <div class="dialog-title">
        <span>
          <p>{{ data.title }}</p>
        </span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="dialog-message-container background-container">
      <div class="dialog-resume-icon">
        <mat-icon *ngIf="data.icon" [class]="data.backgroundIconColor">{{ data.icon }}</mat-icon>
        <mat-icon *ngIf="data.resourceIcon" class="mat-resource-icon">
          <img src="{{ data.resourceIcon }}" alt="resource icon">
        </mat-icon>
      </div>
      <div class="dialog-container-resume-text">
        <div class="dialog-resume-text">
          <span [innerHTML]="sanitizedResume"></span>
        </div>
        <div class="dialog-question">
          <span>{{ data.question }}</span>
        </div>
      </div>
  </div>
  <div class="dialog-buttons-container">
    <button *ngIf="data.cancel"
      mat-stroked-button
      id="dsbic-cancel-button"
      class="secondary-button dialog-button cancel-button" 
      (click)="onClickCancelButton()">
      {{ data.cancel }}
    </button>
    <button
      mat-stroked-button
      id="dsbic-continue-button"
      class="primary-button dialog-button continue-button"
      (click)="onClickContinueButton()">
        <img *ngIf="data.iconButtonPath" src="{{ data.iconButtonPath }}" class="button-icon">
        {{ data.continue }}
    </button>
  </div>
</div>
