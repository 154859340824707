export const REGISTER_FREIGHT_INVOICES_PROPERTIES = {
  detailsActionIcon: 'visibility',
  dialogRegisterFreight: '650px',
  excelButtonId: 'exportExcelButton',
  excelMinRowWidth: 25,
  excelTimeout: 2000,
  freightChargeProperty: 'Flete',
  generalSearchHeaderSize: '40px',
  generalSearchIcon: '../../../assets/icons/general-search.svg',
  gridPageSize: 25,
  gridPaginatorOptions: [25, 50, 100],
  limitHours: 24,
  limitMiliseconds: 1000,
  limitMinutes: 60,
  maxDaysMonth: 31,
  maxDaysRange: 30,
  paidStatus: 'Pagada',
  pendingStatus: 'Pendiente',
  requiredControls: ['beginDate', 'endDate', 'carrierControlName'],
  specificSearchHeaderSize: '2.5em',
  specificSearchIcon: '../../../assets/icons/specific-search.svg',
  timeOut: 100
};

export const GRID_COLUMNS = [
  'folio',
  'carrierName',
  'creationDate',
  'notes',
  'invoice',
  'invoiceNotes',
  'invoiceDate',
  'status',
  'subTotal',
  'vat',
  'total'
];

export const GRID_COLUMNS_PROPERTIES = {
  carrierName: 'carrierName',
  creationDate: 'creationDate',
  creationDateView: 'creationDate',
  folio: 'folio',
  invoiceDate: 'invoiceDate',
  invoiceDateView: 'invoiceDateView',
  invoiceFolio: 'invoice',
  invoiceNotes: 'invoiceNotes',
  notes: 'notes',
  notesView: 'notesView',
  status: 'status',
  subTotal: 'subTotal',
  total: 'total',
  vat: 'vat'
};

export const GRID_COLUMNS_ACTIONS = {
  add: 'add',
  details: 'details',
  edit: 'edit'
};

export const BILLING_SHEET_DETAILS_GRID_COLUMNS = {
  additionalCharges: 'additionalCharges',
  freight: 'freight',
  internalReference: 'internalReference',
  shipmentFolio: 'shipmentFolio',
  total: 'total',
  vat: 'vat'
};
