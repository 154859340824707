import { Injectable } from '@angular/core';

import { AppConstants } from '../../app/constants/app-constants.constants';
import { IBillingSheetPost } from '../interfaces';

@Injectable()

/**
 * @description Service with methods to format differents properties from billing sheets.
 */
export class BillingSheetService {
  constructor() { }

  /**
   * Receives data from billing sheet to build as string the creation date of that billing sheet.
   * @param {IBillingSheetPost} billingSheet - Data of billing sheet.
   * @returns {string} Returns as string the creation date of received billing scheme (YYYY-MM-DD HH:MM:SS).
   */
  public buildBillingSheetCreationDate(billingSheet: IBillingSheetPost): string {
    const dateTime = billingSheet.creationDate.split(AppConstants.DATE_SEPARATOR);
    const time = dateTime[1].split(AppConstants.DOT_CHAR);

    return `${dateTime[0]} ${time[0]}`;
  }

  /**
   * @description Returns label status of billing sheet if have invoice registered or pending.
   * @param {string} pendingLabel - Label for pending status in current language.
   * @param {string} paidLabel - Label for paid status in current language.
   * @param {string} status - Status of billing sheet.
   * @returns {string} Label to display for billing sheet status.
   */
  public getInvoiceStatusLabel(pendingLabel: string, paidLabel: string, status?: string): string {
    if (!status) {
      return pendingLabel;
    } else {
      return paidLabel;
    }
  }
}
