<div class="general-container">
  <span>
    <button
      id="fid-return-button"
      mat-button
      (click)="hideDetails()"
      class="button back-class flex-class hide-details-btn">
      <mat-icon class="icon">
        keyboard_arrow_left
      </mat-icon>
    </button>
    <h3 class="title-page title-page-margins"> {{ labels.title | translate }} {{ billingSheetDetails[0].folio }} </h3>
  </span>

  <mat-divider class="mat-divider-header"></mat-divider>

  <mat-chip-list #chipsList aria-orientation="horizontal">
    <mat-chip class="chip-class" disabled *ngFor="let account of accounts">
      {{ account }}
    </mat-chip>
  </mat-chip-list>

  <div *ngIf="showGrid">
    <div class="grid-table">
      <scf-grid
        [columnToggler]="true"
        [selectable]="false"
        [pageSize]="gridDefaultPageSize"
        [pageSizeOptions]="gridPageSizeOptions"
        [data]="billingSheetDetails"
        [columns]="gridColumns"
        [rowDraggable]="false">
      </scf-grid>
    </div>
  </div>
</div>

<ng-template #additionalCharges let-element="element" let-row="row">
  <ng-container>
    <span matTextPrefix>{{ moneySign }} {{ row.additionalCharges | number: numberDigits: numberFormat }}</span>
  </ng-container>
</ng-template>

<ng-template #freight let-element="element" let-row="row">
  <ng-container>
    <span matTextPrefix>{{ moneySign }} {{ row.freight | number: numberDigits: numberFormat }} </span>
  </ng-container>
</ng-template>

<ng-template #vat let-element="element" let-row="row">
  <ng-container>
    <span matTextPrefix>{{ moneySign }} {{ row.vat | number: numberDigits: numberFormat }} </span>
  </ng-container>
</ng-template>

<ng-template #total let-element="element" let-row="row">
  <ng-container>
    <span matTextPrefix>{{ moneySign }} {{ row.total | number: numberDigits: numberFormat }} </span>
  </ng-container>
</ng-template>
