import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ScfGridColumn } from 'scf-library';

import {
  BILLING_SHEET_DETAILS_GRID_COLUMNS,
  REGISTER_FREIGHT_INVOICES_PROPERTIES
} from '../../pages/costs/register-freight-invoices/register-freight-invoices.properties';
import { AppConstants } from '../../constants/app-constants.constants';
import { BillingSheetDetail } from '../../../app/interfaces';
import { FreightInvoicesDetailsLabels } from '../../../app/interfaces/labels/freight-invoices-labels';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';

@Component({
  selector: 'app-freight-invoices-details',
  styleUrls: ['../../app.component.scss', './freight-invoices-details.component.scss'],
  templateUrl: './freight-invoices-details.component.html'
})

/**
 * Component to display details from an specific billing sheet.
 */
export class FreightInvoicesDetailsComponent implements OnInit {
  /**
   * @description Builds an instance of all the services necessaries for this component to work correctly.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to subscribe to the changes in language triggered by user.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve labels in language active for the user.
   */
  constructor(
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.isDisplayingDetails = new EventEmitter<boolean>();
    this.setLanguage();
    this.moneySign = AppConstants.CURRENCY_SIGN;
    this.numberDigits = AppConstants.NUMBER_DIGITS;
    this.numberFormat = AppConstants.US_NUMBER_FORMAT;
  }

  /**
   * @description Gets the labels for the component in the current language setted.
   */
  public async getRegisterFreightInvoicesLabels(): Promise<void> {
    try {
      this.labels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.REGISTER_FREIGHT_DETAILS_LABELS);
    } catch (error) { }
  }

  /**
   * @description Emits a Flag to hide details from current billing sheet and returns to all billing sheet registered view.
   */
  public hideDetails(): void {
    this.isDisplayingDetails.emit(false);
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvents();
    await this.getRegisterFreightInvoicesLabels();
    this.initScfGridColumns();
    this.gridPageSizeOptions = REGISTER_FREIGHT_INVOICES_PROPERTIES.gridPaginatorOptions;
    this.gridDefaultPageSize = REGISTER_FREIGHT_INVOICES_PROPERTIES.gridPageSize;
    this.showGrid = true;
  }

  /**
   * @description Inits columns for scfGrid.
   */
  private initScfGridColumns(): void {
    this.gridColumns = [
      {
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.shipmentFolio,
        header: this.labels.gridColumns.shipmentFolio
      },
      {
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.internalReference,
        header: this.labels.gridColumns.internalReference
      },
      {
        columnTemplate: this.freight,
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.freight,
        header: this.labels.gridColumns.freight
      },
      {
        columnTemplate: this.additionalCharges,
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.additionalCharges,
        header: this.labels.gridColumns.additionalCharges
      },
      {
        columnTemplate: this.vat,
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.vat,
        header: this.labels.gridColumns.vat
      },
      {
        columnTemplate: this.total,
        field: BILLING_SHEET_DETAILS_GRID_COLUMNS.total,
        header: this.labels.gridColumns.total
      }
    ];
  }

  /**
   * @description Reacts to the language change's event setted by configuration in the interface.
   * @param {string} languageKey - Optional language key string. Default is spanish 'es'.
   */
  private setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Subscribes to the language service to detect changes in the language setted and refresh component's labels and scf grid.
   */
  private subscribeLanguageChangeEvents(): void {
    this.languageSubscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.showGrid = false;
        this.setLanguage(key);
        await this.getRegisterFreightInvoicesLabels();
        setTimeout(() => {
          this.initScfGridColumns();
          this.showGrid = true;
        }, REGISTER_FREIGHT_INVOICES_PROPERTIES.timeOut);
      }
    );
  }

  @Input() public accounts: Array<string>;
  @Input() public billingSheetDetails: Array<BillingSheetDetail>;
  @Output() public isDisplayingDetails: EventEmitter<boolean>;
  @ViewChild('additionalCharges', { static: true }) additionalCharges: TemplateRef<any>;
  @ViewChild('freight', { static: true }) freight: TemplateRef<any>;
  @ViewChild('vat', { static: true }) vat: TemplateRef<any>;
  @ViewChild('total', { static: true }) total: TemplateRef<any>;
  public gridColumns: Array<ScfGridColumn>;
  public gridPageSizeOptions: Array<number>;
  public gridDefaultPageSize: number;
  public labels: FreightInvoicesDetailsLabels;
  public languageSubscription: Subscription;
  public moneySign: string;
  public numberDigits: string;
  public numberFormat: string;
  public showGrid: boolean;
}
